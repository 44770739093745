:root {
    --width-img-logo: 205px;
    --font-size-header-nav: 20px;
    --font-size-header-nav: 16px;
    --gap-header-nav-li: 10px;
    --padding-nav: 15px 30px;
    --padding-navbar-ul: 20px 10px;
    --background-color-menu-btn: #c3264b;
}

nav {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-nav);
    gap: 30px;
    z-index: 9;
    position: relative;
    // background-color: #65abe7;
    .header-logo-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-self: center;
        .img-logo {
            width: var(--width-img-logo);
            z-index: 1;
        }
    }
}

.navbar-list-small {
    height: 100vh;
    width: 0%;
    transition: all 0.2s ease-in-out;
    position: absolute;
    background: rgba(255, 255, 255, 0.521);
    z-index: 8;
    backdrop-filter: blur(2px);
    overflow: hidden;
    display: flex;
    top: 0;
    right: 0;
    ul {
        padding: var(--padding-navbar-ul);
        list-style: none;
        background-color: linear-gradient(188deg, rgb(255 247 255 / 91%) 10%, #ffb56b57 100%);
        text-align: left;
        li {
            margin: 5px;
        }
        a {
            padding: 10px 20px;
            font-size: 20px;
            font-weight: 600;
            text-decoration: none;
            color: #333;
            display: block;
            line-height: 31px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            border-radius: 15px;
            background-color: #ff3c75;
            color: #fff;
            &:hover {
                cursor: pointer;
                background-color: #7fbbf0;
                path {
                    fill: #7fbbf0;
                }
            }
            path {
                fill: #ff3c75;
            }
        }
    }
    & > div {
        height: 100%;
        width: -webkit-fill-available;
        // background-image: linear-gradient(
        //     to right,
        //     rgb(255, 255, 255),
        //     rgba(255, 255, 255, 0),
        //     rgba(255, 255, 255, 0),
        //     rgba(255, 255, 255, 0),
        //     rgba(255, 255, 255, 0)
        // );
    }
}
.navbar-small-container-svg {
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar-small-show {
    width: 100% !important;
    top: 0;
    height: 100vh;
}

.header-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    border-radius: 15px;
    padding: 0;
    gap: var(--gap-header-nav-li);

    li {
        background-color: #fff;
        border: none;
        outline: none;
        padding: 5px 5px;
        border-radius: 50px;

        box-shadow: 0 2px 5px 0 #0000003b;
        font-size: var(--font-size-header-nav);
        font-weight: 1000;
        transition: all 0.3s ease;
        &:hover {
            cursor: pointer;
            background: linear-gradient(to right, #deff7136, #ff7c7c7a);
            background-color: rgb(236 246 255 / 95%);
        }

        &:active {
            transform: scale(0.95);
            background: linear-gradient(to right, #deff7136, #ff7c7c7a);
            background-color: rgb(236 246 255 / 95%);
        }

        color: #c3264b;
        text-decoration: none;
        white-space: nowrap;
        a {
            color: #c3264b;
            text-decoration: none;
            white-space: nowrap;
        }
    }
}

.dropdown {
    width: 110px;
    user-select: none;
    position: relative;
    img {
        width: 20px;
        height: 15px;
    }
}
.dropdown .dropdown-btn {
    gap: 10px;
    // background-color: #fff;
    // box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: var(--font-size-header-nav);
    font-weight: 1000;
    white-space: nowrap;
    color: #c3264b;
    text-decoration: none;
    white-space: nowrap;
}
.dropdown .dropdown-content {
    position: absolute;
    z-index: 100;
    // top: 110%;
    // padding: 10px;
    background-color: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    width: 100%;
    // box-sizing: border-box;
    padding: 0;
    padding: 5px;
    left: 0;
    transform: translate(-5px, 113%);
    bottom: 0;
    top: unset;
    border-radius: 9px;
    box-sizing: content-box;
}
.dropdown .dropdown-content .dropdown-item {
    padding: 5px 0;
    box-sizing: border-box;
    font-size: var(--font-size-dropdown-item);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #c3264b;
    &:hover {
        background-color: #f4f4f4;
    }
}
button:has(.close-mark) {
    background-color: transparent;
    border: none;
}
.close-mark {
    width: 24px !important;
    height: 17px !important;
    // background-color: white !important;
    position: relative;
    border-bottom: 2px solid var(--background-color-menu-btn);
    transition: all 200ms linear;
    display: inline-block;
    width: 1.5em;
    // height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    // font-size: 1.25rem;
    // line-height: 1;
    &::before {
        width: 24px;
        position: absolute;
        height: 2px;
        background-color: var(--background-color-menu-btn);
        top: 0;
        left: 0;
        content: "";
        z-index: 2;
        transition: all 200ms linear;
    }
    &::after {
        width: 24px;
        position: absolute;
        height: 2px;
        background-color: var(--background-color-menu-btn);
        top: 8px;
        left: 0;
        content: "";
        z-index: 2;
        transition: all 200ms linear;
    }
}
.transform-after {
    transform: rotate(45deg);
}
.transform-before {
    transform: translateY(8px) rotate(-45deg);
}
.transform-main {
    border-color: transparent;
    &::after {
        transform: rotate(45deg);
    }
    &::before {
        transform: translateY(8px) rotate(-45deg);
    }
}
// Checkbox change language navbar small
.navbarsmall-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media all and (max-width: 1150px) {
    :root {
        --font-size-header-nav: 14px;
        --font-size-dropdown-item: 14px;
        overflow-x: hidden;
        max-width: 100vw;
        --width-img-logo: 135px;
        --gap-header-nav-li: 5px;
    }
    .dropdown {
        width: 85px;
    }
}
@media all and (max-width: 850px) {
    :root {
        --padding-nav: 10px 10px;
        --padding-navbar-ul: 120px 10px;
    }
    .navbarsmall-btn-container {
        background: #f6f0f0;
        padding: 10px 20px;
        border-radius: 63px;
    }
}


@media all and (max-width: 650px) {
    :root {
        --padding-navbar-ul: 120px 10px;
    }
 
}