:root {
    --font-size-widget-text-container-h1: 48px;
    --font-size-widget-text-container-p: 24px;
    --font-size-widget-text-container-a: 16px;

    --left-home-float-container: 0;
    --right-home-float-container: 0;

    --width-widget-container: 70%;
    --min-height-widget-img-container: 500px;
}
.page-conatiner-box {
    width: 100%;
    margin: auto;
}
.widget-container {
    width: 100%;
    position: relative;
}
.widget-main {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: var(--width-widget-container);
    margin: auto;
    iframe {
        margin-top: 20px;
        margin-bottom: 20px;
        max-height: 500px;
        max-width: 100%;
    }
    div {
        width: 50%;
        img {
            min-width: 400px;
        }
    }
}
.home-float-container {
    position: absolute;
    top: 50%;
    left: var(--left-home-float-container);
    width: 365px !important;
    height: 365px;
    border-radius: 73% 27% 22% 78% / 64% 82% 18% 36%;
    background-color: #65abe7;
    z-index: 9;
    transform: translate(-50%, 0%);
    transition: all 0.3s ease;
}
.widget-container:nth-child(even) {
    .widget-main {
        flex-direction: row-reverse;
        .home-float-container {
            left: unset;
            right: var(--right-home-float-container);
            transform: translate(50%, 0%);
        }
    }
    .widget-text-container {
        padding: 50px 0px 50px 30px;
    }
    // .home-gallery-button-a{
    //     align-self: flex-end;
    // }
    .home-float-container {
        background-color: #f3cafb;
    }
    .home-gallery-button-a {
        background-color: #65abe7;
    }
}
.widget-img-container {
    background-color: hsla(0, 0%, 5%, 0.749);
    color: #f0f8ff;
    min-height: var(--min-height-widget-img-container);
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // background-image: url(https://images.unsplash.com/photo-1546447911-95677822edea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80);
    background-size: cover;
    background-position: center;
    // max-height: 400px;
    // img{
    //     height: 100%;
    // }
}
.widget-text-container {
    padding: 50px 30px 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 250px;
    h1 {
        font-size: var(--font-size-widget-text-container-h1);
        font-weight: 600;
        color: #012c6c;
    }
    p {
        font-size: var(--font-size-widget-text-container-p);
        font-weight: 500;
        color: #012c6c;
    }
    a {
        font-size: var(--font-size-widget-text-container-a);
        &:hover {
            filter: brightness(0.96);
        }
    }
}
.home-gallery-button-a {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: #ff70a0;

    color: white;
    box-sizing: border-box;
    padding: 10px 30px;
    border-radius: 63px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.widged-top {
    margin: auto;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #012c6c;
    box-sizing: border-box;
    background-image: url(https://ams03pap001files.storage.live.com/y4mWciHGQFcDmuRWO9hlruHh9AApsOhpiuGyHnTSYmwf7Kx2aMWMNed0cDnTUxr9ITQxkLy8hUU_Ur9JgrFgmlqQQLkga2Y6Dpq42cbjSrJYlt9Z81b0oJHyPIAl4TndoZ7LibO9U1P_T9y59w5Strzajr7xB8-gl7eVYgAfilCJIJSWmX5NLz_-YIyw08riRRv?width=1024&height=331&cropmode=none);
    div {
        margin: auto;
        background: #ecf6fff2;
        height: 100%;
        padding: 100px 30px;
        h1 {
            margin: auto;
            width: 70%;
        }
    }
    margin-bottom: 30px;
}
.main-a-pic{
    display: block;
    min-width: 50%;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    div {
        min-width: 100%;
        transition: all ease-in-out 0.3s;
    }
    &:hover div{
        transform: scale(1.1);
        background-color: red;
    }
}

@media all and (min-width: 1850px) and (max-width: 2250px) {
    :root {
        --font-size-widget-text-container-h1: 36px;
        --font-size-widget-text-container-p: 20px;
        --font-size-widget-text-container-a: 20px;

        --width-widget-container: 70%;
        --min-height-widget-img-container: 600px;
    }
}

@media all and (min-width: 1500px) and (max-width: 1850px) {
    :root {
        --font-size-widget-text-container-h1: 36px;
        --font-size-widget-text-container-p: 20px;
        --font-size-widget-text-container-a: 20px;

        --width-widget-container: 70%;
        --min-height-widget-img-container: 500px;
    }
}


@media all and (min-width: 1200px) and (max-width: 1500px) {
    :root {
        --font-size-widget-text-container-h1: 32px;
        --font-size-widget-text-container-p: 18px;
        --font-size-widget-text-container-a: 18px;

        --width-widget-container: 70%;
        --min-height-widget-img-container: 400px;
    }
}


@media all and (min-width: 805px) and (max-width: 1200px) {
    :root {
        --font-size-widget-text-container-h1: 28px;
        --font-size-widget-text-container-p: 16px;
        --font-size-widget-text-container-a: 16px;

        --width-widget-container: 75%;
        --min-height-widget-img-container: 340px;

        --left-home-float-container: -8%;
        --right-home-float-container: -8%;
    }
}
@media all and (max-width: 805px) {
    :root {
        --font-size-widget-text-container-h1: 24px;
        --font-size-widget-text-container-p: 14px;
        --font-size-widget-text-container-a: 12px;

        --min-height-widget-img-container: 400px;
        --padding-navbar-ul: 120px 10px;
    }
    .navbarsmall-btn-container {
        background: #f6f0f0;
        padding: 10px 20px;
        border-radius: 63px;
    }
    .widget-main,
    .widget-container:nth-child(even) .widget-main {
        flex-direction: column-reverse;
        width: calc(100% - 10px);

        div {
            width: 100%;
        }
    }
    .widget-container:nth-child(even) .widget-text-container,
    .widget-text-container {
        padding: 5px;
    }
    .home-gallery-button-a,
    .widget-container:nth-child(even) .home-gallery-button-a {
        align-self: center;
    }
    .home-float-container {
        z-index: -1;
    }

    .home-gallery-button-a {
        margin-bottom: 25px;
    }
    .page-conatiner-box{
            max-width: 450px;
    }
}

