@import url("https://fonts.googleapis.com/css2?family=Grandstander:wght@300&display=swap");

.gallery-title {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  img {
    width: 150px;
  }

  h1 {
    font-family: "Grandstander", cursive;
  }
}