.gallery {
  -webkit-column-count: 3;
  -webkit-column-width: 33%;
  columns: 33% 3;
  padding: 0 12px;
}
.gallery .pics {
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 0.35s ease;
}
// img {
//   width: 100%;
//   vertical-align: middle;
//   // max-height: 400px;
//   object-fit: cover;
// }
.arrow.arrow-next .arrow-top,
.arrow.arrow-prev .arrow-top {
  width: 35px;
  left: -10px;
}
.gallery-gotop-button {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 2px springgreen;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  justify-content: center;
  align-items: center;
  transform: translate(-10%, -100%);
  transition: all 0.3s ease-in-out;
  background: linear-gradient(
    188deg,
    rgba(159, 5, 255, 0.4117647059) 10%,
    rgba(253, 94, 8, 0.4196078431) 100%
  );
  path {
    fill: white;
    opacity: 0.6;
  }
}
.gallery-btn-fade-in {
  opacity: 1;
}
.gallery-btn-fade-out {
  opacity: 0;
}
@media all and (max-width: 850px) {
  .gallery {
    columns: 2;
  }
  .gallery-gotop-button {
    display: flex;
  }
}

@media all and (max-width: 500px) {
  .gallery {
    columns: 1;

    .pics {
      pointer-events: none;
    }
  }
}

@media all and (min-width: 1400px) {
  .gallery {
    columns: 4;
  }
}

.arrow.arrow-next .arrow-top,
.arrow.arrow-prev .arrow-top {
  width: 35px;
  left: -10px;
}

.grid-gallery{
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    padding: 0px 12px;
    display: grid;
    
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
    grid-gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
}

.grid-gallery .grid-item {
    position: relative;
    background-color: #efefef;
    overflow: hidden;
    border-radius: 5px;
}

.grid-gallery .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.grid-gallery .grid-item:hover img {
    transform: scale(1.1);
}
.grid-gallery .grid-item a {
    cursor: zoom-in;
}
.grid-gallery .grid-item:nth-child(3n - 2) {
    grid-column: span 2;
    grid-row:  span 2;
}
.grid-gallery .grid-item:nth-child(9n - 2) {
    grid-column: span 1;
    grid-row:  span 2;
}
@media (max-width: 768px) {
    .grid-gallery {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-auto-rows: 250px;
    }
  
    .grid-gallery .grid-item {
      grid-column: unset;
      grid-row: unset;
    }
  }
@media (max-width: 468px) {
    .grid-gallery {
        display: flex;
        grid-template-columns: none;
        grid-gap: unset;
        flex-wrap: wrap;
        gap: 10px;
    }
  
    .grid-gallery .grid-item {
      grid-column: unset;
      grid-row: unset;
    }
  }