@import "variables.scss";
*{
    margin: 0;
    padding: 0;
}
:root {
    --font-size-footer-navbar: 22px;
    }
.footer-container
{
    margin-top: 20px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    background-color: #65abe7;
    z-index: 10;
    & >div{
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        line-height: 30px;
        align-items: flex-end;
        &:first-child{
            align-items: flex-start;
        }
        
    }
    // & >div
    & img{
        width: 100px;
    }
    p{
        font-size: 12px;;
    }
}
.footer-navbar{
    a{
        text-decoration: none;
        color: white;
        transition: all 0.3s ease-in-out;
        &:hover{
            cursor: pointer;
            color: #f5c9f1;
        }
    }
}
*{
    margin: 0;
    padding: 0;
}
.pics{
transition: all 0.3s ease-in-out;
border-radius: 5px;
overflow: hidden;
    &:hover{
        cursor: pointer;
        scale: 0.98;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
}
.footer-navbar{
    list-style: none;
    display: flex;  
    flex-wrap: wrap;
    gap: 10px;
    font-size: var(--font-size-footer-navbar);
    font-weight: 600;
    color: #fff;
    margin: 0;
    padding: 0;
}
div.footer-services{
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    img{
        width: 50px;
        height: 50px;
    }
}

@media all and (max-width: 850px) {
    :root {
    --font-size-footer-navbar: 14px;
    }
    .footer-container{
        flex-direction: column;
        div{
            width: 100%;
        }
    }
    .footer-navbar,p, div.footer-services {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}