:root{
    --font-size-contact-details-div-a: 24px;
    --font-size-contact-details-h2: 24px;
    --width_contact-img:100%;
    --width-contact-div:50%;
    --padding-contact-details: 30px 0;
}
.contact{
margin-top: 30px;
color:#012c6c;
position: relative;
.home-float-container{
    
    transform: translate(-50%, 0%);
    background-color: #65abe7;
    transition: all 1s ease;
}
}
.contact-container{
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    min-height: 300px;
    div{
        // width: 50%;
        font-size: 28px;
        font-weight: 600;
        color: #012c6c;
    }
}
.contact-main{
    width: 70%;
    margin: auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    padding: var( --padding-contact-details);
    font-size: 22px;
    h2{
        text-align: start;
    }
    &>div{
        width: var(--width-contact-div);
        img{
            width: var(--width_contact-img);
            max-width: 450px;
            max-height: 450px;
        }
    }
}
.contact-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    
    & div a{
        display: flex;
        align-items: center;
        gap: 20px;
        text-decoration: none;
        color: #012c6c;
        font-size: var(--font-size-contact-details-div-a);
        transition: all 0.3s ease;
        &:hover{
            color: #65abe7;
        }
    }
    h2{
        font-size: var(--font-size-contact-details-h2);
    }
}
@media all and (max-width: 850px) {
    :root {
    --font-size-contact-details-div-a: 16px;

        --font-size-contact-details-h2: 18px;
        --padding-contact-details: 5px;

        --width_contact-img: 100%;
        --width-contact-div:100%;
    }
    .contact-main{
        flex-direction: column;
        width: calc(100% - 10px);
    }
    .contact-details{
        h2{
            text-align: center;
        }
        div{
            display: flex;
            justify-content: center;
        }
    }
}